import React from "react"

import PageHeader from "components/Ui/Page/PageHeader"
import EventRules from "../../../components/ExternalEvents/EventRules/EventRules"
import EventBuses from "../../../components/ExternalEvents/EventBus/EventBuses"
import DestinationAPIs from "../../../components/ExternalEvents/DestinationApi/DestinationAPIs"
import { Grid, Info, InfoWrapper } from "./ExternalEvents.styled"
import { ReactComponent as AWSIcon } from "../../../images/icons/aws-brands-solid.svg"
import { ReactComponent as Code } from "../../../images/icons/laptop-code.svg"
import { ReactComponent as Book } from "../../../images/icons/book-open.svg"
import { ReactComponent as Retry } from "../../../images/icons/clock-rotate-left.svg"
import PrimaryButton from "../../../components/Ui/Buttons/PrimaryButton"

export const ExternalEvents = () => (
  <>
    <PageHeader
      title="External events"
      description={<>Manage your external events in Brink Commerce.</>}
    >
      {" "}
      <a
        href="https://docs.brinkcommerce.com/retail-api/general/events-in-brink/"
        target="_blank"
        rel="noreferrer"
      >
        <PrimaryButton>
          <Book />
          Read more
        </PrimaryButton>
      </a>
    </PageHeader>
    <div>
      <h2>You can subscribe to events in two ways</h2>

      <Info>
        <Code />
        <div>Webhook: Specify a URL to receive event data.</div>
      </Info>
      <Info>
        <AWSIcon />
        <div>
          AWS EventBridge: Utilize AWSs event bus service for more complex workflows. The events
          carry all the necessary data, eliminating the need to fetch additional information via the
          API.
        </div>
      </Info>
    </div>
    <Grid>
      <DestinationAPIs />
      <EventBuses />
    </Grid>
    <h2>Retry policy</h2>
    <Info>
      <InfoWrapper>
        <div>
          <Retry />
          <div>
            <b>Webhook API destinations</b>
          </div>
          <div>
            Maximum age of event: <b>1h</b>
          </div>
          <div>
            Retry attempts: <b>5</b>
          </div>
        </div>
        <div>
          <Retry />
          <div>
            <b>Event bus</b>
          </div>
          <div>
            EventBridge retries sending the event for 24 hours and up to 185 times with an exponential
            back off and jitter, or randomized delay.
          </div>
          <a
            href={
              "https://docs.aws.amazon.com/eventbridge/latest/userguide/eb-rule-retry-policy.html"
            }
            target={"_blank"}
            rel="noreferrer"
          >
            Read more
          </a>
        </div>
      </InfoWrapper>

    </Info>
    <EventRules />
  </>
)
