import React from "react"
import { DeliveryOrderLine, Order, OrderRefund } from "lib/types/generated/graphql-types"
import { Id, Name, ProductImage, RefundButtonWrapper, Row } from "../Shared.styled"
import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import { useMutation } from "@apollo/client"
import CREATE_REFUND from "../../../../../graphql/mutations/order/refunds/CreateRefund"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import UPDATE_REFUND from "../../../../../graphql/mutations/order/refunds/UpdateRefund"
import { ReactComponent as RefundIcon } from "../../../../../images/icons/arrow-rotate-left-thick.svg"
import Status from "../../../../Ui/Status"
import { getRefundInput } from "../Helpers/helpers"
import { handleErrorMessages } from "helpers/errors"
import { handleImageError } from "helpers/image"

type Props = {
  orderLine: DeliveryOrderLine
  order: Order
  currencyCode: string
  refunds: OrderRefund[]
  refetch: () => void
}

export const VariantRow = ({ orderLine, order, currencyCode, refunds, refetch }: Props) => {
  const notCompletedRefund = refunds.find((refund) => !refund.started)
  const completedRefunds = refunds.filter((refund) => !!refund.completed)
  const orderLineRefunded = completedRefunds.map((refund) =>
    refund.orderLines.find((ol) => ol.orderLineId === orderLine.orderLineId)
  )
  const getRefundedQuantity = () =>
    orderLineRefunded.reduce((a, b) => {
      return a + (b?.quantity ?? 0)
    }, 0)

  const [createRefund, { loading: createLoading }] = useMutation(CREATE_REFUND, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const [updateRefund, { loading: updateLoading }] = useMutation(UPDATE_REFUND, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const orderLineExistInRefund = (id: string) => {
    return notCompletedRefund?.orderLines.find((ol) => ol.orderLineId === id)
  }

  const getOrderLine = () => {
    return order?.orderLines?.find((ol) => ol.id === orderLine.orderLineId)
  }

  const createOrUpdateOrderRefund = () => {
    if (!notCompletedRefund) {
      return createRefund({
        variables: {
          orderId: order.id,
          input: {
            orderLines: {
              orderLineId: orderLine.orderLineId,
              quantity: orderLine.quantity - getRefundedQuantity()
            }
          }
        }
      })
    }
    return updateRefund({
      variables: {
        refundId: notCompletedRefund.id,
        input: {
          ...getRefundInput(notCompletedRefund),
          orderLines: [
            ...notCompletedRefund.orderLines.map((ol) => ({
              orderLineId: ol.orderLineId,
              quantity: ol.quantity
            })),
            {
              orderLineId: orderLine.orderLineId,
              quantity: orderLine.quantity - getRefundedQuantity()
            }
          ]
        }
      }
    })
  }

  return (
    <Row key={orderLine.orderLineId}>
      <ProductImage src={getOrderLine()?.imageUrl as string} onError={handleImageError} />
      <div>
        <Name>{getOrderLine()?.name}</Name>
        <Money
          amount={orderLine.totalAmount}
          currencyUnit={currencyCode as DineroFactory.Currency}
        ></Money>

        <div>Order quantity: {orderLine.quantity}</div>
        {getRefundedQuantity() > 0 && <div>Refunded quantity: {getRefundedQuantity()}</div>}
        <Id>
          <b>Variant ID:</b> <span>{getOrderLine()?.productVariantId}</span>
        </Id>
      </div>
      <RefundButtonWrapper>
        {getRefundedQuantity() === orderLine.quantity ? (
          <Status status="Refunded" />
        ) : (
          <SecondaryButton
            handleClick={createOrUpdateOrderRefund}
            disabled={!!orderLineExistInRefund(orderLine.orderLineId)}
            loading={createLoading || updateLoading}
          >
            <RefundIcon />
            Refund
          </SecondaryButton>
        )}
        <span>
          {" "}
          Total :{" "}
          <b>
            {" "}
            <Money
              amount={orderLine.totalAmount}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />
          </b>
        </span>
      </RefundButtonWrapper>
    </Row>
  )
}
