import React, { useState } from "react"
import styled from "styled-components"
import dayjs from "dayjs"
import { useMutation } from "@apollo/client"
import { isSuperUser } from "helpers/user"
import { AdyenWebhook } from "lib/types/generated/graphql-types"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useConfig } from "configuration/useConfig"

import GENERATE_ADYEN_CREDENTIALS from "graphql/mutations/config/GenerateAdyenCredentials"

import { Loader } from "../../Ui/Loader"
import CopyButton from "../../Ui/CopyButton"
import TableRow from "../../Ui/Table/TableRow"

import { ReactComponent as UpdateIcon } from "images/icons/rotate-right.svg"
import { ReactComponent as Code } from "images/icons/code.svg"

type Props = {
  webhook: AdyenWebhook
}

const StyledTableRow = styled(TableRow)`
  &:hover {
    cursor: default;
    background: rgba(255, 255, 255, 0.5);
  }
`

const UpdatePassword = styled.div`
  display: flex;
  align-items: center;

  svg {
    transition: all 0.5s ease-out;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;

    svg {
      transform: rotate(180deg);
    }
  }
`

const NewPassword = styled.div`
  display: flex;
  font-size: 1rem;
`
const Password = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    height: 1.5rem;
    margin: 0.3rem;
  }
`

const Footer = styled.div`
  display: block !important;
  height: auto !important;
  margin-bottom: 2rem;

  span {
    ${(p) => p.theme.bold};
  }
`

export const getLoadingRow = () => (
  <>
    <div>
      <div>
        <Code />
        <p>Webhook id</p>
      </div>
    </div>
    <div className="hideOnSmallScreens">
      <p>Username</p>
    </div>
    <Password>
      <>
        <UpdateIcon />
        <UpdatePassword>Generate new password</UpdatePassword>
      </>
    </Password>
    <div className="hideOnSmallScreens hideOnMeduimScreens">
      <p>2000-00-00: 00:00</p>
    </div>
    <div className="hideOnSmallScreens hideOnMeduimScreens">
      <p>2000-00-00: 00:00</p>
    </div>
  </>
)

export const AdyenWebhookTableRow = ({ webhook }: Props) => {
  const [newPassword, setNewPassword] = useState<string>("")
  const { customerName } = useConfig().config
  const brinkUrl = `https://shopper.eu-west-1.${customerName}.brinkcommerce.io/shopper-adyen/notifications?wid=${webhook.id}`

  const dispatch = useAppDispatch()

  const [generateAdyenCredentials, { loading: createLoading }] = useMutation(
    GENERATE_ADYEN_CREDENTIALS,
    {
      onCompleted: (data) => {
        setNewPassword(data?.generateAdyenWebhookCredentials?.password)
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )
  return (
    <StyledTableRow
      subtitle={
        <Footer>
          <span>Copy and use URL for setting up webhook in Adyen:</span>
          <div>
            {brinkUrl}
            <CopyButton string={brinkUrl} />
          </div>
        </Footer>
      }
    >
      <Code />
      <div>
        <p>{webhook.id}</p>
      </div>
      <div className="hideOnSmallScreens">
        <p>{webhook.username}</p>
      </div>
      <Password>
        {isSuperUser() ? (
          createLoading ? (
            <Loader color="black" />
          ) : newPassword ? (
            <NewPassword>
              {newPassword}
              <CopyButton string={newPassword} />
            </NewPassword>
          ) : (
            <UpdatePassword>
              <UpdateIcon />
              <div onClick={() => generateAdyenCredentials({ variables: { id: webhook.id } })}>
                Generate new password
              </div>
            </UpdatePassword>
          )
        ) : (
          <p>**********</p>
        )}
      </Password>
      <div className="hideOnSmallScreens hideOnMeduimScreens">
        <p>{dayjs(webhook.created).format("YYYY-MM-DD, HH:mm")}</p>
      </div>
      <div className="hideOnSmallScreens hideOnMeduimScreens">
        <p>{dayjs(webhook.updated).format("YYYY-MM-DD, HH:mm")}</p>
      </div>
    </StyledTableRow>
  )
}
