import React, { useState } from "react"
import { OrderCompensation, OrderLine, ShippingFee } from "lib/types/generated/graphql-types"

import dayjs from "dayjs"
import Money from "../../Money"
import DineroFactory from "dinero.js"
import {
  AdditionalData,
  BaseData,
  Bold,
  CompensationFee,
  Container,
  Dates,
  ExpandIcon,
  Footer,
  Id,
  ImageContainer,
  Info,
  Label,
  NotCompleted,
  OrderLineWrapper,
  PaymentInfo,
  Percentage,
  PriceWrapper,
  ProductImage,
  ProviderStatus,
  Reason,
  Row,
  TotalTitle
} from "./Shared.styled"
import { ReactComponent as DownIcon } from "images/icons/chevron-down.svg"
import { ReactComponent as ShippingIcon } from "images/icons/cart-flatbed-boxes.svg"
import Status from "../../Ui/Status"
import { useQuery } from "@apollo/client"
import LoadingRow from "./LoadingRow"
import GET_COMPENSATION from "../../../graphql/queries/order/GetCompensation"
import CompensationTotals from "./CompensationTotals"
import { handleImageError } from "helpers/image"

type Props = {
  compensationId: string
  currencyCode: string
  orderLines: OrderLine[]
  shippingFees: ShippingFee[]
}
const Compensation = ({ compensationId, currencyCode, orderLines, shippingFees }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  const getOrderLine = (id: string) => {
    return orderLines?.find((ol) => ol.id === id)
  }

  const getShippingFee = (id: string) => {
    return shippingFees?.find((fee) => fee.id === id)
  }

  const getGiftCardStatus = (status: string) => {
    switch (status) {
      case "REFUNDED":
        return "Refunded"
      case "REFUND_FAILED":
        return "Failed"
      default:
        return status
    }
  }

  const { data, loading } = useQuery(GET_COMPENSATION, {
    variables: { compensationId: compensationId }
  })

  const compensation = data?.Order?.compensation as OrderCompensation
  const compensationOrderLines = compensation?.orderLines ?? []
  const giftCards = compensation?.giftCards ?? []

  if (loading) return <LoadingRow />
  return (
    <Container>
      {!compensation.completed && <NotCompleted>Not completed</NotCompleted>}
      <BaseData onClick={() => setExpanded(!expanded)}>
        <h3>Compensation #{compensation.id}</h3>
        <Dates>
          <div>
            Started: <b>{dayjs(compensation.created).format("YYYY-MM-DD : HH:mm:ss")}</b>
          </div>
          <div>
            Last updated: <b>{dayjs(compensation.updated).format("YYYY-MM-DD : HH:mm:ss")}</b>
          </div>
          {compensation.completed && (
            <div>
              Completed: <b>{dayjs(compensation.completed).format("YYYY-MM-DD : HH:mm:ss")}</b>
            </div>
          )}
          <div>
            Order lines: <b>{compensationOrderLines.length}</b>
          </div>
          {compensation?.restarted && (
            <>
              <div>
                Restarted: <b>{dayjs(compensation.restarted).format("YYYY-MM-DD : HH:mm:ss")}</b>
              </div>
              <div>
                Restarts: <b>{compensation.restarts}</b>
              </div>
            </>
          )}
        </Dates>
      </BaseData>
      {compensation.reason && (
        <Reason>
          Reason:{" "}
          <b>
            {compensation.reason?.code}, {compensation.reason?.cause}
          </b>
        </Reason>
      )}
      {expanded && (
        <AdditionalData>
          {compensationOrderLines.map((ol) => {
            const orderLineCompensationAmount =
              ol.totalDiscountAmount - (getOrderLine(ol.orderLineId)?.discountAmount ?? 0)

            const getCompensationPercentage = () => {
              const percentage = (orderLineCompensationAmount / ol.totalAmount) * 100
              return Number.isInteger(percentage) ? percentage : percentage.toFixed(1)
            }

            return (
              <OrderLineWrapper key={ol.orderLineId}>
                <Info>
                  <ImageContainer>
                    <ProductImage
                      src={getOrderLine(ol.orderLineId)?.imageUrl as string}
                      onError={handleImageError}
                    />
                  </ImageContainer>
                  <Percentage>
                    {Number.isInteger(getCompensationPercentage())
                      ? getCompensationPercentage()
                      : `~${getCompensationPercentage()}`}
                    %
                  </Percentage>
                  <div>
                    {getOrderLine(ol.orderLineId)?.name}
                    <PriceWrapper>
                      <div>
                        Total:
                        <Money
                          amount={parseInt(`${ol.totalAmount}`)}
                          currencyUnit={currencyCode as DineroFactory.Currency}
                        />
                      </div>
                      <div>
                        {getOrderLine(ol.orderLineId)?.discountAmount ? (
                          <>
                            Discount: -
                            <Money
                              amount={parseInt(`${getOrderLine(ol.orderLineId)?.discountAmount}`)}
                              currencyUnit={currencyCode as DineroFactory.Currency}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div>
                        Compensation: -
                        <Money
                          amount={orderLineCompensationAmount}
                          currencyUnit={currencyCode as DineroFactory.Currency}
                        />
                      </div>
                    </PriceWrapper>
                    <Id>{getOrderLine(ol.orderLineId)?.productVariantId}</Id>
                  </div>
                </Info>
              </OrderLineWrapper>
            )
          })}
          {compensation?.shippingFees?.map((fee) => {
            const shippingFeeCompensationAmount =
              fee.totalDiscountAmount - (getShippingFee(fee.shippingFeeId)?.discountAmount ?? 0)

            const getCompensationPercentage = () => {
              const percentage = (shippingFeeCompensationAmount / fee.totalAmount) * 100
              return Number.isInteger(percentage) ? percentage : percentage.toFixed(1)
            }

            return (
              <OrderLineWrapper key={fee.shippingFeeId}>
                <Info>
                  <span>
                    <ShippingIcon />
                    <Percentage>
                      {Number.isInteger(getCompensationPercentage())
                        ? getCompensationPercentage()
                        : `~${getCompensationPercentage()}`}
                      %
                    </Percentage>
                  </span>
                  <div>
                    <Bold>Shipping: {getShippingFee(fee.shippingFeeId)?.displayName}</Bold>
                    <PriceWrapper>
                      <div>
                        Total:
                        <Money
                          amount={fee?.totalAmount ?? 0}
                          currencyUnit={currencyCode as DineroFactory.Currency}
                        />
                      </div>
                      <div>
                        {getShippingFee(fee.shippingFeeId)?.discountAmount ? (
                          <>
                            Discount: -
                            <Money
                              amount={getShippingFee(fee.shippingFeeId)?.discountAmount ?? 0}
                              currencyUnit={currencyCode as DineroFactory.Currency}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div>
                        Compensation: -
                        <Money
                          amount={shippingFeeCompensationAmount ?? 0}
                          currencyUnit={currencyCode as DineroFactory.Currency}
                        />
                      </div>
                    </PriceWrapper>
                    <Id>{fee.shippingFeeId}</Id>
                  </div>
                </Info>
              </OrderLineWrapper>
            )
          })}
          <ProviderStatus>
            <div>
              <Label>Payment status:</Label>
              <Status status={compensation?.paymentProvider?.status?.current ?? ""} />
            </div>
            {compensation?.bonusProvider && (
              <div>
                <Label>Bonus status:</Label>{" "}
                <Status status={compensation?.bonusProvider?.status?.current ?? ""} />
              </div>
            )}
            {compensation?.giftCardProvider && (
              <div>
                <Label>Gift card status:</Label>
                <Status status={compensation?.giftCardProvider?.status?.current ?? ""} />
              </div>
            )}
          </ProviderStatus>
          {compensation.fee && (
            <CompensationFee>
              <div>
                Compensation fee name: <b>{compensation.fee?.name}</b>
              </div>
              <div>
                Compensation fee tax:{" "}
                <b>
                  {compensation.fee.taxPercentage / 10 ** compensation.fee.taxPercentageDecimals +
                    "%"}
                </b>
              </div>
              <div>
                Compensation fee amount:{" "}
                <b>
                  <Money
                    amount={compensation.fee.amount}
                    currencyUnit={currencyCode as DineroFactory.Currency}
                  />
                </b>
              </div>
            </CompensationFee>
          )}
          <PaymentInfo>
            {compensation?.payment?.reference && (
              <div>
                Payment reference: <b>{compensation?.payment?.reference}</b>
              </div>
            )}
            {compensation?.bonus && (
              <div>
                Bonus reservationId: <b>{compensation?.bonus?.reservationId}</b>
              </div>
            )}
            {giftCards.length > 0 && (
              <>
                {`Gift card${giftCards.length > 1 ? "s" : ""}:`}
                {giftCards.map((giftCard) => (
                  <Row key={giftCard.giftCardId}>
                    <span>
                      Id: <b>{giftCard.giftCardId}</b>
                    </span>
                    <span>
                      Amount:{" "}
                      <b>
                        <Money
                          amount={giftCard.amount}
                          currencyUnit={currencyCode as DineroFactory.Currency}
                        />
                      </b>
                    </span>
                    <span>
                      Status: <Status status={getGiftCardStatus(giftCard.status)} />
                    </span>
                  </Row>
                ))}
              </>
            )}
          </PaymentInfo>
          <CompensationTotals
            totals={compensation.totals}
            currencyCode={currencyCode}
            label="Compensated amount"
          />
        </AdditionalData>
      )}
      <Footer>
        <div>
          <TotalTitle>Total:</TotalTitle>
          <TotalTitle>
            <Money
              amount={parseInt(`${compensation.totals.grandTotal}`)}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />
          </TotalTitle>
        </div>
      </Footer>
      <ExpandIcon $expanded={expanded} onClick={() => setExpanded(!expanded)}>
        <DownIcon />
      </ExpandIcon>
    </Container>
  )
}

export default Compensation
