import styled from "styled-components"



export const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;

`

export const Label = styled.h4`
margin: 1rem 0 0 0;
`

export const AddedReason =styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${p => p.theme.colors.white};
  border: 0.1rem solid ${p => p.theme.colors.borderLight};
  border-radius: 0.6rem;
  padding: 1rem;

  span {
    margin-right: 1rem;
  }

  svg {
    height: 1.6rem;

    &:hover {
      cursor: pointer;
      fill: ${(p) => p.theme.colors.grey};
    }
  }
`

export const MissingReason = styled.div`
display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Wrapper = styled.div`
`

export const ErrorMessage = styled.div`
color: ${p => p.theme.colors.errorText};
`

