import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import PageSection from "../../Ui/Page/PageSection"
import PageSectionHeader from "../../Ui/Page/PageSectionHeader"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import CreateDiscountCode from "../../../views/Discounts/DiscountCode/CreateDiscountCode"
import UpdateDiscountCode from "../../../views/Discounts/DiscountCode/UpdateDiscountCode"
import GenerateDiscountCodes from "../../../views/Discounts/DiscountCode/GenerateDiscountCodes"
import Search from "../../Ui/Search"
import { DiscountCodeTable } from "../DiscountCode/DiscountCodeTable"
import styled from "styled-components"
import PrimaryButton from "../../Ui/Buttons/PrimaryButton"
import esb from "elastic-builder"
import { Query } from "lib/types/common"
import { useLazyQuery } from "@apollo/client"
import SEARCH_DISCOUNT_CODES from "../../../graphql/queries/discount/SearchDiscountCodes"
import { useAppDispatch } from "lib/store"
import { DiscountCodeRule, DiscountCodeSearchHit } from "lib/types/generated/graphql-types"
import { ReactComponent as TagIcon } from "images/icons/tag.svg"
import { ReactComponent as TagsIcon } from "images/icons/tags.svg"

const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    justify-content: flex-end;
  }

  button {
    margin: 0 0 2rem 0;
    align-self: flex-end;

    ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
      margin: 0 0 4rem 0;
    }
  }

  .hideOnSmallScreens {
    white-space: pre-wrap;
  }
`

const AddButton = styled(PrimaryButton)`
  position: relative;
`

type Props = {
  discountRule: DiscountCodeRule | undefined
  discountCodes: DiscountCodeSearchHit[]
  setDiscountCodes: Dispatch<SetStateAction<DiscountCodeSearchHit[]>>
  loading: boolean
}

const AddDiscountCodes = ({ discountRule, discountCodes, setDiscountCodes, loading }: Props) => {
  const [editCode, setEditCode] = useState("")
  const [generateCodes, setGenerateCodes] = useState<boolean>(false)
  const [createDiscountCode, setCreateDiscountCode] = useState<boolean>(false)
  const [searchQuery, setSearchQuery] = useState<string>("")
  const dispatch = useAppDispatch()

  const referenceSearchQuery = esb.requestBodySearch().query(
    esb
      .boolQuery()
      .must(esb.matchQuery("discountCodeRuleId", discountRule?.id ?? ""))
      .must([esb.queryStringQuery(`*${searchQuery}*`).analyzeWildcard(true)])
  )

  const query = referenceSearchQuery.toJSON() as Query
  const variables = {
    from: 0,
    size: 25,
    sort: [{ field: "updated", order: "ASC" }],
    query: JSON.stringify(query.query)
  }

  const [searchDiscountCodes, { loading: codeLoading, error: codeError, refetch }] = useLazyQuery(
    SEARCH_DISCOUNT_CODES,
    {
      variables
    }
  )

  useEffect(() => {
    if (discountRule) {
      const delayDebounceFn = setTimeout(
        () => {
          searchDiscountCodes({
            variables
          }).then((response) => {
            setDiscountCodes(response?.data?.searchDiscountCodes.hits)
          })
        },
        searchQuery === "" ? 0 : 300
      )
      return () => clearTimeout(delayDebounceFn)
    }
  }, [searchQuery, generateCodes])

  return (
    <>
      <PageSection>
        <PageSectionHeader title="Discount codes" description="Connect discount codes to rule" />
        <ActionButtons>
          <AddButton
            type="button"
            handleClick={() => {
              dispatch(showEditSidebar())
              setGenerateCodes(true)
            }}
          >
            <TagsIcon /> Generate <span className="hideOnSmallScreens"> discount </span> codes
          </AddButton>
          <AddButton
            type="button"
            handleClick={() => {
              dispatch(showEditSidebar())
              setCreateDiscountCode(true)
            }}
          >
            <TagIcon /> Create <span className="hideOnSmallScreens"> discount </span> code
          </AddButton>
        </ActionButtons>
      </PageSection>
      {createDiscountCode && (
        <CreateDiscountCode
          discountCodeRuleId={discountRule?.id ?? ""}
          setCreateDiscountCode={setCreateDiscountCode}
          setDiscountCodes={setDiscountCodes}
          discountCodes={discountCodes}
        />
      )}
      {editCode && (
        <UpdateDiscountCode
          discountCode={editCode}
          setEditId={setEditCode}
          setDiscountCodes={setDiscountCodes}
        />
      )}
      {generateCodes && (
        <GenerateDiscountCodes
          discountCodeRuleId={discountRule?.id ?? ""}
          setGenerateCodes={setGenerateCodes}
          refetch={refetch}
        />
      )}
      <Search
        handleOnChange={(input) => setSearchQuery(input)}
        placeholder="Search discount codes"
        loading={codeLoading}
        defaultValue={searchQuery}
      />
      <div>
          <DiscountCodeTable
            discountCodes={discountCodes}
            setCodeToShow={setEditCode}
            loading={loading}
            error={codeError}
          />
      </div>
    </>
  )
}

export default AddDiscountCodes
