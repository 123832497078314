import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import {
  Order,
  OrderLine,
  OrderLineModificationCreateData,
  OrderLineModificationType,
  OrderLineModificationUpdateData,
  OrderModificationOrderLines,
  ProductVariant
} from "lib/types/generated/graphql-types"
import {
  BasePrice,
  BlurRow,
  Button,
  Buttons,
  Id,
  Name,
  ProductImage,
  Row,
  Status,
  TotalPrice,
  TotalsWrapper
} from "../Shared.styled"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import { useLazyQuery, useMutation } from "@apollo/client"
import { handleErrorMessages } from "helpers/errors"
import CREATE_MODIFICATION from "../../../../../graphql/mutations/order/modification/CreateModification"
import { ReactComponent as EditIcon } from "images/icons/gear.svg"
import { ReactComponent as DeleteIcon } from "images/icons/trash-can.svg"
import GET_PRODUCT_VARIANT from "../../../../../graphql/queries/product/GetProductVariant"
import { useDispatch } from "react-redux"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import UPDATE_MODIFICATION from "../../../../../graphql/mutations/order/modification/UpdateModification"
import { createOutputToInput, updateOutputToInput } from "../Helpers"
import { handleImageError } from "helpers/image"

type Props = {
  orderLine: OrderLine
  order: Order
  currencyCode: string
  refetch: () => void
  setEditOrderLine: Dispatch<SetStateAction<OrderLine | undefined>>
  setProductVariant: Dispatch<SetStateAction<ProductVariant | undefined>>
  notCompletedModifications: OrderModificationOrderLines[]
}

export const VariantRow = ({
  orderLine,
  order,
  currencyCode,
  refetch,
  setEditOrderLine,
  setProductVariant,
  notCompletedModifications
}: Props) => {
  const [fetchedProductVariant, setFetchedProductVariant] = useState<ProductVariant>()

  const dispatch = useDispatch()

  const [getProductVariant] = useLazyQuery(GET_PRODUCT_VARIANT, {
    onCompleted: (data) => setFetchedProductVariant(data.Product.variant),
    onError: (error) => handleErrorMessages(error)
  })

  useEffect(() => {
    if (orderLine.productVariantId)
      getProductVariant({ variables: { id: orderLine.productVariantId } })
  }, [order])

  const handleEditClick = () => {
    if (fetchedProductVariant) {
      setProductVariant(fetchedProductVariant)
      setEditOrderLine(orderLine)
      dispatch(showEditSidebar())
    }
  }

  const getOrderLine = () => {
    return order?.orderLines?.find((ol) => ol.id === orderLine.id)
  }

  const [createModification, { loading: createLoading }] = useMutation(CREATE_MODIFICATION, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const [updateModification, { loading: updateLoading }] = useMutation(UPDATE_MODIFICATION, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const deleteOrderLine = (orderLineId: string) => {
    if (notCompletedModifications.length === 0) {
      createModification({
        variables: {
          orderId: order.id,
          input: {
            orderLines: [
              {
                modificationType: OrderLineModificationType.Delete,
                data: JSON.stringify({ id: orderLineId })
              }
            ]
          }
        }
      })
      return
    }
    const currentOrderLines = notCompletedModifications[0].orderLines
    updateModification({
      variables: {
        modificationId: notCompletedModifications[0].id,
        input: {
          orderLines: [
            ...currentOrderLines.map((orderLine) => ({
              ...orderLine,
              data:
                orderLine.modificationType === OrderLineModificationType.Update
                  ? JSON.stringify(
                      updateOutputToInput(orderLine.data as OrderLineModificationUpdateData)
                    )
                  : orderLine.modificationType === OrderLineModificationType.Create
                  ? JSON.stringify(
                      createOutputToInput(orderLine.data as OrderLineModificationCreateData)
                    )
                  : JSON.stringify(orderLine.data)
            })),
            {
              modificationType: OrderLineModificationType.Delete,
              data: JSON.stringify({ id: orderLineId })
            }
          ]
        }
      }
    })
  }

  const getStatus = (status: OrderLineModificationType) => {
    switch (status) {
      case OrderLineModificationType.Update:
        return "Updated"
      case OrderLineModificationType.Create:
        return "Created"
    }
    return status
  }

  const orderLineInModification = notCompletedModifications.flatMap((modification) =>
    modification.orderLines.find(
      (modificationOrderLine) => orderLine.id === modificationOrderLine.data.id
    )
  )

  if (createLoading || updateLoading)
    return (
      <BlurRow>
        <ProductImage src={getOrderLine()?.imageUrl as string} onError={handleImageError} />
        <div>
          <Name>{getOrderLine()?.name}</Name>
          <Money
            amount={orderLine.salePriceAmount}
            currencyUnit={currencyCode as DineroFactory.Currency}
          ></Money>
          <div>Order quantity: {orderLine.quantity}</div>
          <Id>
            <b>Variant ID:</b> <span>{getOrderLine()?.productVariantId}</span>
          </Id>
        </div>
        <TotalsWrapper>
          <Buttons>
            <Button>
              <EditIcon />
            </Button>
            <Button>
              <DeleteIcon onClick={() => deleteOrderLine(orderLine.id)} />
            </Button>
          </Buttons>
          <span>
            {" "}
            Total :{" "}
            <b>
              {" "}
              <Money
                amount={orderLine.totalPriceAmount}
                currencyUnit={currencyCode as DineroFactory.Currency}
              />
            </b>
          </span>
        </TotalsWrapper>
      </BlurRow>
    )

  const hasDiscount = () => orderLine.basePriceAmount !== orderLine.salePriceAmount

  return (
    <Row key={orderLine.id}>
      <ProductImage src={getOrderLine()?.imageUrl as string} onError={handleImageError} />
      <div>
        {orderLineInModification.length > 0 && orderLineInModification[0]?.modificationType && (
          <Status>{getStatus(orderLineInModification[0]?.modificationType) ?? ""}</Status>
        )}
        <Name>{getOrderLine()?.name}</Name>
        {hasDiscount() && (
          <BasePrice>
            <Money
              amount={orderLine.basePriceAmount}
              currencyUnit={currencyCode as DineroFactory.Currency}
            ></Money>
          </BasePrice>
        )}
        <div>
          <Money
            amount={orderLine.salePriceAmount}
            currencyUnit={currencyCode as DineroFactory.Currency}
          ></Money>
        </div>
        <div>Order quantity: {orderLine.quantity}</div>
        <Id>
          <b>Variant ID:</b> <span>{getOrderLine()?.productVariantId}</span>
        </Id>
      </div>
      <TotalsWrapper>
        {orderLineInModification[0]?.modificationType !== OrderLineModificationType.Create && (
          <Buttons>
            <Button onClick={handleEditClick}>
              <EditIcon />
            </Button>
            {orderLineInModification[0]?.modificationType !== OrderLineModificationType.Update && (
              <Button onClick={() => deleteOrderLine(orderLine.id)}>
                <DeleteIcon />
              </Button>
            )}
          </Buttons>
        )}
        <span>
          {" "}
          <TotalPrice>
            Discount: -
            <Money
              amount={orderLine.distributedTotalDiscountAmount}
              currencyUnit={currencyCode as DineroFactory.Currency}
            ></Money>
          </TotalPrice>
          <TotalPrice>
            Total :{" "}
            <b>
              {" "}
              <Money
                amount={orderLine.distributedTotalPriceAmount}
                currencyUnit={currencyCode as DineroFactory.Currency}
              />
            </b>
          </TotalPrice>
        </span>
      </TotalsWrapper>
    </Row>
  )
}
