import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useLazyQuery } from "@apollo/client"
import { OrderSearchHits } from "@lib/types/generated/graphql-types"
import { Query } from "@lib/types/common"
import { useLocalStorage } from "hooks/useLocalStorage"
import { Common } from "@lib/types"

import SEARCH_ORDERS from "graphql/queries/order/SearchOrders"

import { OrderTable } from "components/Order/OrderTable"
import PageHeader from "components/Ui/Page/PageHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import OrderFilters from "components/Order/OrderFilters"

import { ReactComponent as RefreshIcon } from "images/icons/rotate-right.svg"
import { isReadOnlyUser } from "helpers/user"

const Container = styled.div`
  width: 100%;
  min-height: 20rem;
  position: relative;
`

const RefreshContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
`

const NUMBER_OF_HITS_OPTIONS = ["25", "50", "100", "150", "200", "250"]

const DEFAULT_ORDER_FILTER = {
  status: [] as Common.Option[],
  storeGroups: [] as Common.Option[],
  countries: [] as Common.Option[],
  payments: [] as Common.Option[],
  fromDate: "",
  toDate: "",
  numberOfHits: NUMBER_OF_HITS_OPTIONS[0],
  searchQuery: ""
}

export const ShowOrders = () => {
  const [orders, setOrders] = useState<OrderSearchHits>()
  const [esbQuery, setEsbQuery] = useState<Query>()
  const [orderFilter, setOrderFilter] = useLocalStorage<Common.OrderFilter>(
    "orderFilter",
    DEFAULT_ORDER_FILTER
  )

  const setSearchQuery = (value: string) =>
    setOrderFilter({ ...orderFilter, ...{ searchQuery: value } })

  const query = esbQuery?.toJSON() as Query

  const [searchOrders, { loading, error }] = useLazyQuery(SEARCH_ORDERS, {
    onCompleted: (data) => {
      setOrders(data?.searchOrders)
    }
  })

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        if (query) {
          searchOrders({
            variables: {
              from: 0,
              size: orderFilter?.numberOfHits,
              sort: [{ field: "date", order: "DESC" }],
              query: JSON.stringify(query?.query),
              trackTotalHits: true
            }
          }).then((response) => {
            setOrders(response?.data?.searchOrders)
          })
        }
      },
      orderFilter?.searchQuery === "" ? 0 : 300
    )
    return () => clearTimeout(delayDebounceFn)
  }, [orderFilter?.searchQuery, esbQuery, orderFilter.numberOfHits])

  return (
    <Container>
      <PageHeader title="Orders"></PageHeader>
      <RefreshContainer>
        <PrimaryButton
          handleClick={() => window.location.reload()}
          title={"Refresh orders"}
          overrideDisabled={isReadOnlyUser()}
        >
          <RefreshIcon /> Refresh
        </PrimaryButton>
      </RefreshContainer>
      <OrderFilters
        setSearchQuery={setSearchQuery}
        loading={loading}
        searchQuery={orderFilter?.searchQuery ?? ""}
        setEsbQuery={setEsbQuery}
        orderFilter={orderFilter}
        setOrderFilter={setOrderFilter}
        numberOfHitsOption={NUMBER_OF_HITS_OPTIONS}
        defaultOrderFilter={DEFAULT_ORDER_FILTER}
        totalHits={orders?.total}
      />
      <OrderTable
        orderData={orders}
        loading={loading}
        searchQuery={orderFilter?.searchQuery}
        error={error}
      />
    </Container>
  )
}
