import React, { ReactNode } from "react"
import styled from "styled-components"

const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 0 2rem 0;
  ${(p) => p.theme.bold}
  color: ${(p) => p.theme.colors.greyDarker};
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  font-size: 1.2rem;

  > div {
    flex: 1;
    display: flex;
    height: 4rem;
    align-items: center;
    border-left: 0.1rem solid ${(p) => p.theme.colors.greyLight};
    padding-left: 1.5rem;
    flex-direction: row;

    ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
      .hideOnSmallScreens {
        display: flex !important;
      }
    }
  }

  p {
    margin: 0;
  }
`

type Props = {
  children: ReactNode | ReactNode[]
}

const TableHeader = ({ children, ...props }: Props) => {
  return <Container {...props}>{children}</Container>
}

export default TableHeader
