import gql from "graphql-tag"

const GET_ORDER = gql`
  query getOrder($id: ID!) {
    Order {
      history(id: $id) {
        history {
          message
          operation
          operationId
          status
          timestamp
          type
          errorMessage
        }
        operations {
          delivered {
            bonus{
              amount
            }
            giftCards {
              amount
              giftCardId
            }
            gifts {
              giftId
              quantity
            }
            giftCardProducts {
              giftCardProductId
            }
            orderLines {
              orderLineId
              quantity
            }
            shippingFees {
              shippingFeeId
            }
            vouchers {
              amount
              voucherId
            }
          }
          refunded {
            bonus {
              amount
            }
            giftCards {
              amount
              giftCardId
            }
            gifts {
              giftId
              quantity
            }
            orderLines {
              orderLineId
              quantity
            }
            shippingFees {
              shippingFeeId
            }
            vouchers {
              amount
              voucherId
            }
          }
          compensated {
            payment {
              amount
            }
            bonus {
              amount
            }
            giftCards {
              amount
              giftCardId
            }
          }
        }
        states
      }
      details(id: $id) {
        id
        date
        reference
        languageCode
        countryCode
        currencyCode
        storeGroupId
        gifts {
          id
          displayName
          productParentId
          productVariantId
          quantity
          basePriceAmount
          salePriceAmount
          totalPriceAmount
          discountAmount
          currencyCode
          totalTaxAmount
          taxPercentage
          taxPercentageDecimals
          imageUrl
        }
        isTaxIncludedInPrice
        attributes {
          merchantReference1
          merchantReference2
        }
        totals {
          subTotal
          taxTotal
          giftCardTotal
          discountTotal
          shippingTotal
          grandTotal
          bonusTotal
        }
        discountCodeRules {
          id
          name
          code
          currencyCode
          discountAmount
          freeShipping
        }
        discountRules {
          id
          name
          currencyCode
          discountAmount
          freeShipping
        }
        discountExternalRules {
          currencyCode
          discountAmount
          freeShipping
          id
          name
          reference
        }
        discountOutcome {
          discountReduction
          totalDiscountAmount
          freeShipping
          cartRules {
            discountAmount
            ruleID
          }
          codeRules {
            code
            discountAmount
            ruleID
          }
          externalRules {
            discountAmount
            reference
            ruleID
          }
        }
        shippingFees {
          id
          name
          displayName
          taxGroupId
          currencyCode
          basePriceAmount
          salePriceAmount
          discountAmount
          taxAmount
          taxPercentage
          taxPercentageDecimals
        }
        orderLines {
          id
          quantity
          productVariantId
          productParentId
          name
          displayName
          description
          displayDescription
          taxGroupId
          currencyCode
          basePriceAmount
          salePriceAmount
          discountAmount
          taxPercentage
          taxPercentageDecimals
          imageUrl
          totalPriceAmount
          totalTaxAmount
          totalDiscountAmount
          distributedTotalPriceAmount
          distributedTotalTaxAmount
          distributedTotalDiscountAmount
          campaign {
            campaignId
            campaignGroupId
            name
          }
          discountOutcome {
            totalDiscountAmount
            bundles {
              bundleGroupId
              bundleId
              discountAmount
            }
            cartRules {
              discountAmount
              ruleID
            }
            codeRules {
              code
              discountAmount
              ruleID
            }
            externalRules {
              discountAmount
              reference
              ruleID
            }
          }
        }
        shippingAddress {
          givenName
          familyName
          email
          telephoneNumber
          streetAddress
          streetAddress2
          postalCode
          city
          stateOrProvince
          country
          pcc
        }
        billingAddress {
          givenName
          familyName
          email
          telephoneNumber
          streetAddress
          streetAddress2
          postalCode
          city
          stateOrProvince
          country
        }
        status {
          orderStates
        }

        Payment {
          __typename
          providerId
          providerName
          reference
          method

          ... on OrderPaymentAdyen {
            status
            merchantAccountCode
            failedReason
            potentialFraud
            deepLinkPath
          }

          ... on OrderPaymentKlarnaCheckout {
            status
            fraudStatus
            klarnaMerchantId
            klarnaOrderId
            deepLinkPath
          }

          ... on OrderPaymentSveaCheckout {
            status
            sveaMerchantId
            sveaOrderId
            expirationDate
            peppolId
            isCompany
          }

          ... on OrderPaymentWalleyCheckout {
            amountToPay
            purchaseIdentifier
            purchaseResult
            status
            totalAmount
          }

          ... on OrderPaymentQliroOne {
            status
            paymentTypeCode
            requireIdentityVerification
            identityVerified
            privateVerificationCode
            qliroOrderId
            signupForNewsletter
            merchantProvidedQuestionAnswer
            orderItems {
              merchantReference
              paymentTransactionId
            }
          }
          ... on OrderPaymentAvarda {
            status
            purchaseId
          }
          ... on OrderPaymentBrinkZeroPayment {
            originalProvider {
              id
              name
            }
          }
        }
        Shipping {
          __typename
          providerId
          providerName
          reference
          ... on OrderShippingAvardaShippingBroker {
            shippingBroker
            shipping {
              ingrid {
                deliveryType
                tosId
                carrier
                externalMethodId
                location {
                  externalId
                  address {
                    addressLines
                    city
                    name
                    postalCode
                  }
                }
              }
            }
          }
          ... on OrderShippingIngrid {
            deliveries {
              name
              deliveryType
              tosId
              carrierProductId
              deliveryAddress {
                name
                addressLines
                city
                postalCode
              }
            }
          }
        }
        GiftCard {
          __typename
          orderId
          providerId
          providerName
          status

          ... on OrderGiftCardBSGGiftCard {
            giftCards {
              id
              amount
              currencyCode
              reservationId
              status
              failedReason
            }
          }

          ... on OrderGiftCardKBSGiftCard {
            giftCards {
              id
              amount
              currencyCode
              reservationId
              status
              failedReason
            }
          }

          ... on OrderGiftCardRetain24 {
            giftCards {
              id
              amount
              currencyCode
              reservationId
              status
              failedReason
            }
          }
        }
        GiftCardProduct {
          ... on OrderGiftCardProductRetain24 {
            __typename
            giftCardProducts {
              amount
              currencyCode
              distribution
              emailText
              id
              name
              recipient
              merchantReference
              referenceNumber
              sendDate
              smsText
              templateId
            }
            orderId
            providerId
            providerName
          }
        }
        Promotion {
          orderId
          providerId
          providerName
          status
          ... on OrderPromotionVoyado {
            __typename
            contactId
            promotions {
              value
              failedReason
              id
              status
            }
          }
        }
        Voucher {
          providerName
          providerId
          orderId
          status
          ... on OrderVoucherVoyado {
            __typename
            contactId
            vouchers {
              amount
              checkNumber
              currencyCode
              failedReason
              id
              status
            }
          }
        }
        Bonus {
          amount
          currencyCode
          customerId
          failedReason
          points
          providerId
          providerName
          reservationId
          status
        }
        Deliveries {
          id
          started
          failed
          completed
          created
          restarts
          restarted
          totals {
            subTotal
            shippingTotal
            giftCardTotal
            bonusTotal
            grandTotal
          }
          capturedPayment {
            reference
          }
          vouchers {
            amount
            currencyCode
            voucherId
          }
          orderLines {
            currencyCode
            orderLineId
            quantity
            taxPercentage
            taxPercentageDecimals
            totalAmount
            totalDiscountAmount
            totalTaxAmount
          }
          gifts {
            giftId
            quantity
          }
          giftCardProvider {
            providerName
            providerId
          }
          giftCards {
            amount
            giftCardId
            reservationId
            status
            currencyCode
          }
          giftCardProducts {
            giftCardProductId
            priceAmount
            currencyCode
            createId
          }
          bonusProvider {
            providerName
            providerId
          }
          bonus {
            amount
            reservationId
          }
          shippingProvider {
            providerName
            providerId
          }
          paymentProvider {
            providerName
            providerId
          }
          shippingFees {
            currencyCode
            shippingFeeId
            totalAmount
          }
          tracking {
            reference
            shippingCompany
            shippingMethod
            url
          }
        }
        Modifications {
          id
          started
          completed
          failed
          created
          updated
          restarts
          restarted
          revision
          bonusProvider {
            providerName
            providerId
          }
          giftCardProductProvider {
            providerId
            providerName
            status {
              current
              history {
                message
                status
                timestamp
                type
              }
            }
          }
          giftCardProvider {
            providerId
            providerName
          }
          paymentProvider {
            providerId
            providerName
          }
          reason {
            code
            cause
          }
          type
          ... on OrderModificationOrderLines {
            id
            orderReference
            oldTotals {
              subTotal
              discountTotal
              grandTotal
              taxTotal
            }
            newTotals {
              subTotal
              discountTotal
              grandTotal
              taxTotal
            }
            orderLines {
              data {
                ... on OrderLineModificationCreateData {
                  id
                  name
                  productVariantId
                  basePriceAmount
                  description
                  discountAmount
                  imageUrl
                  productParentId
                  quantity
                  salePriceAmount
                  totalDiscountAmount
                  distributedTotalPriceAmount
                  totalPriceAmount
                  totalTaxAmount
                  taxPercentage
                  taxPercentageDecimals
                }
                ... on OrderLineModificationDeleteData {
                  id
                  productVariantId
                  name
                  imageUrl
                  quantity
                  salePriceAmount
                }
                ... on OrderLineModificationUpdateData {
                  id
                  name
                  description
                  displayName
                  displayDescription
                  imageUrl
                  price {
                    basePriceAmount
                    discountAmount
                    salePriceAmount
                    totalPriceAmount
                    totalTaxAmount
                    taxPercentage
                    taxPercentageDecimals
                    distributedTotalDiscountAmount
                    distributedTotalPriceAmount
                    quantity
                  }
                  productVariantId
                  productParentId
                }
              }
              modificationType
            }
          }
        }
        Refunds {
          id
          started
          completed
          failed
          created
          updated
          restarts
          restarted
          giftCards {
            amount
            currencyCode
            giftCardId
            status
          }
          fee {
            amount
            currencyCode
            name
            taxAmount
            taxPercentage
            taxPercentageDecimals
          }
          giftCardProvider {
            providerId
            providerName
          }
          shippingFees {
            currencyCode
            shippingFeeId
            taxPercentage
            taxPercentageDecimals
            totalAmount
            totalDiscountAmount
            totalTaxAmount
          }
          bonus {
            reservationId
            currencyCode
            amount
          }
          bonusProvider {
            providerId
            providerName
          }
          paymentProvider {
            providerId
            providerName
          }
          refundedPayment {
            reference
          }
          reason {
            cause
            code
          }
          gifts {
            giftId
            quantity
          }
          totals {
            subTotal
            shippingTotal
            giftCardTotal
            bonusTotal
            grandTotal
          }
          orderLines {
            currencyCode
            orderLineId
            quantity
            taxPercentage
            taxPercentageDecimals
            totalAmount
            totalDiscountAmount
            totalTaxAmount
          }
        }
        Releases {
          id
          started
          failed
          completed
          created
          restarts
          restarted
          revision
          reason {
            cause
            code
          }
          releasedPayment {
            reference
          }
          orderLines {
            currencyCode
            orderLineId
            quantity
            taxPercentage
            taxPercentageDecimals
            totalAmount
            totalDiscountAmount
            totalTaxAmount
          }
          gifts {
            giftId
            quantity
          }
          giftCardProvider {
            providerName
            providerId
          }
          giftCards {
            amount
            giftCardId
            status
            currencyCode
          }
          giftCardProductProvider {
            providerId
            providerName
            status {
              current
              history {
                message
                status
                timestamp
                type
              }
            }
          }
          giftCardProducts {
            cancelErrorMessage
            cancelErrorMessage
            cancelTransactionId
            createFailedReason
            createId
            createTransactionId
            currencyCode
            giftCardProductId
            priceAmount
            status
          }
          bonusProvider {
            providerName
            providerId
          }
          bonus {
            amount
            reservationId
          }
          shippingProvider {
            providerName
            providerId
          }
          paymentProvider {
            providerName
            providerId
          }
          voucherProvider {
            providerName
            providerId
          }
          shippingFees {
            currencyCode
            shippingFeeId
            totalAmount
          }
        }
        Cancellations {
          id
          completed
          started
          failed
          restarts
          restarted
          reason {
            cause
            code
          }
          cancelledPayment {
            reference
          }
          bonusProvider {
            providerId
            providerName
          }
          paymentProvider {
            providerId
            providerName
          }
          bonusProvider {
            providerId
            providerName
          }
          bonus {
            reservationId
            status
          }
          giftCards {
            reservationId
            status
          }
        }
        Compensations {
          bonus {
            amount
            currencyCode
            reservationId
          }
          bonusProvider {
            providerId
            providerName
          }
          completed
          created
          failed
          giftCardProvider {
            providerId
            providerName
          }
          giftCards {
            giftCardId
            amount
          }
          id
          orderLines {
            currencyCode
            imageUrl
            name
            orderLineId
            quantity
            taxPercentage
            taxPercentageDecimals
            totalAmount
            totalDiscountAmount
            totalTaxAmount
          }
          payment {
            amount
            currencyCode
            reference
          }
          paymentProvider {
            providerId
            providerName
          }
          reason {
            cause
            code
          }
          fee {
            amount
            currencyCode
            name
            taxAmount
            taxPercentage
            taxPercentageDecimals
          }
          restarted
          restarts
          revision
          shippingFees {
            currencyCode
            name
            shippingFeeId
            taxPercentage
            taxPercentageDecimals
            totalAmount
            totalDiscountAmount
            totalTaxAmount
          }
          started
          totals {
            bonusTotal
            giftCardTotal
            grandTotal
            paymentTotal
          }
          updated
        }

      }
    }
  }
`

export default GET_ORDER
