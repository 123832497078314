import { AdyenEnvironment, ApiScope, Campaign, CampaignGroup } from "@lib/types/generated/graphql-types"
import { ReactNode } from "react"

export interface View {
  element?: ReactNode
  path: string
  title?: string
  children?: View[]
  showInSidebar?: boolean
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  parentPath?: string
  section?: string
  sortOrder?: number
}

export type Views = Array<View>

export interface Option {
  value: string | AdyenEnvironment
  label: string
  description?: string
}

export interface OrderFilter {
  status: Option[]
  storeGroups: Option[]
  countries: Option[]
  payments: Option[]
  fromDate: string
  toDate: string
  numberOfHits: string
  searchQuery: string
}

export interface ProductFilter {
  isActive: boolean
  isArchived: boolean
  numberOfHits: string
  searchQuery: string
}

export interface ProductPreviewFilter {
  numberOfHits: string
  searchQuery: string
}

export interface CampaignFilter {
  status: string
  searchQuery: string
  createdFromDate: string
  createdToDate: string
}
export interface DiscountRuleFilter {
  isActive: Option[]
  fromDate: string
  toDate: string
  numberOfHits: string
  searchQuery: string
}

export interface BundleGroupFilter {
  isActive: Option[]
  fromDate: string
  toDate: string
  numberOfHits: string
  searchQuery: string
}

export enum RULE_TYPE {
  CART_RULE,
  CODE_RULE,
  EXTERNAL_RULE
}

export interface Money {
  currencyCode: string
  units: number
}

export interface RelativeDiscount {
  percentage: number | undefined
  percentageDecimals: number
}

export enum AUTH_TYPE {
  NONE = "NONE",
  API_KEY = "API_KEY",
  AWS_IAM = "AWS_IAM",
  AMAZON_COGNITO_USER_POOLS = "AMAZON_COGNITO_USER_POOLS",
  OPENID_CONNECT = "OPENID_CONNECT",
  AWS_LAMBDA = "AWS_LAMBDA"
}

export enum PROVIDER_TYPE {
  INGRID = "Ingrid",
  NSHIFT = "Nshift",
  KLARNA_CHECKOUT = "KlarnaCheckout",
  ADYEN = "Adyen",
  SVEA_CHECKOUT = "SveaCheckout",
  AVARDA = "Avarda",
  RETAIN24 = "Retain24",
  BSG_GIFT_CARD = "BSGGiftCard",
  KBS_GIFT_CARD = "KBSGiftCard",
  WALLEY_CHECKOUT = "WalleyCheckout",
  QLIRO_ONE = "QliroOne",
  BONUS = "bonus",
  VOYADO = "voyado"
}

export enum PROVIDER_CATEGORY {
  SHIPPING = "shippingProviders",
  PAYMENT = "paymentProviders",
  GIFTCARD = "giftCardProviders",
  CART_CAPABILITY = "cartCapabilities"
}

type KeysWithType<O, T> = {
  [K in keyof O]: O[K] extends T ? K : never
}[keyof O]

export interface AuthOptions {
  type:
    | KeysWithType<typeof AUTH_TYPE, AUTH_TYPE.AMAZON_COGNITO_USER_POOLS>
    | KeysWithType<typeof AUTH_TYPE, AUTH_TYPE.OPENID_CONNECT>
  jwtToken: (() => string | Promise<string>) | string
}

export interface Query {
  // eslint-disable-next-line
  [key: string]: any
}

export interface Scopes {
  read: ApiScope[]
  write: ApiScope[]
}

export interface GroupedCampaign {
  campaignGroup: CampaignGroup | undefined
  campaigns: Campaign[]
}


