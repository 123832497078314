import React, { Dispatch, SetStateAction } from "react"
import dayjs from "dayjs"
import styled from "styled-components"
import { DiscountCodeSearchHit } from "@lib/types/generated/graphql-types"

import { IconTableHeader, EmptyList, Name, Id } from "components/Ui/Table/Shared.styled"

import TableRow from "components/Ui/Table/TableRow"
import TableBlurLoading from "components/Ui/Table/TableBlurLoading"

import { ReactComponent as ListTreeIcon } from "images/icons/tag.svg"
import { ApolloError } from "@apollo/client"
import ErrorMessage from "../../Ui/Messages/ErrorMessage"

const CodeRuleInfo = styled.div`
  min-width: 45rem;
`

type Props = {
  loading: boolean
  setCodeToShow: Dispatch<SetStateAction<string>>
  discountCodes: DiscountCodeSearchHit[]
  error: ApolloError | undefined
}

export const DiscountCodeTable = ({ discountCodes, setCodeToShow, loading, error }: Props) => {
  const tableHeader = (
    <IconTableHeader>
      <CodeRuleInfo>Code</CodeRuleInfo>
      <div>Valid from</div>
      <div>Valid to</div>
      <div>Created</div>
    </IconTableHeader>
  )

  const getTableRow = (discountCode?: DiscountCodeSearchHit) => {
    if (discountCode)
      return (
        <TableRow key={discountCode.code} handleOnClick={() => setCodeToShow(discountCode.code)}>
          <ListTreeIcon />
          <CodeRuleInfo>
            <div>
              <Name>{discountCode.code}</Name>
              <Id>Rule ID: {discountCode.discountCodeRuleId}</Id>
            </div>
          </CodeRuleInfo>

          <div>
            {discountCode.validFrom ? dayjs(discountCode.validFrom).format("YYYY-MM-DD") : "-"}
          </div>
          <div>{discountCode.validTo ? dayjs(discountCode.validTo).format("YYYY-MM-DD") : "-"}</div>
          <div>{dayjs(discountCode.created).format("YYYY-MM-DD")}</div>
        </TableRow>
      )
    return (
      <>
        <ListTreeIcon />
        <CodeRuleInfo>
          <div>
            <Name>CODE-NAME</Name>
            <Id>Rule ID: 76t283-t43tg-34te43-3453re</Id>
          </div>
        </CodeRuleInfo>
        <div>2000-00-00</div>
        <div>2000-00-00</div>
        <div>2000-00-00</div>
      </>
    )
  }
  if (loading)
    return <TableBlurLoading numberOfRows={10} tableHeader={tableHeader} rows={getTableRow()} />
  if (error)
    return (
      <ErrorMessage
        showRefreshButton
        message={
          <>
            Error loading discount codes. Try refreshing the page, or contact{" "}
            <a href="mailto:support@brinkcommerce.com">support</a>.
          </>
        }
      />
    )
  return discountCodes?.length > 0 ? (
    <>
      {tableHeader}
      {discountCodes.map((discountCode: DiscountCodeSearchHit) => getTableRow(discountCode))}
    </>
  ) : (
    <>
      {!loading && (
        <EmptyList>
          <ListTreeIcon />
          <p> No discount codes found</p>
        </EmptyList>
      )}
    </>
  )
}
