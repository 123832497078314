import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { DeliveryOrderLine, Order, OrderCompensation } from "lib/types/generated/graphql-types"
import {
  Amount,
  AmountInput,
  Compensated,
  Id,
  InputPercentage,
  Name,
  PercentageButton,
  PercentageButtonWrapper,
  PriceWrapper,
  ProductImage,
  RowContainer,
  RowWrapper
} from "../Shared.styled"
import Money from "../../../../Money"
import Input from "../../../../Ui/Form/Input"
import { InputOrderLineCompensationWithAmount } from "@lib/types/order"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import { useMutation } from "@apollo/client"
import UPDATE_COMPENSATION from "../../../../../graphql/mutations/order/compensation/UpdateCompensation"
import {
  compensationOrderLinesOutputToInput,
  getCompensationInput,
  toCompensationOrderLineFromState
} from "../Helpers/helpers"
import DineroFactory from "dinero.js"
import { NoticeBanner } from "../../../../Ui/Messages/NoticeBanner"
import { handleErrorMessages } from "helpers/errors"
import { handleImageError } from "helpers/image"

type Props = {
  orderLine: DeliveryOrderLine
  order: Order
  currencyCode: string
  compensations: OrderCompensation[]
  refetch: () => void
  setInputOrderLines: Dispatch<SetStateAction<InputOrderLineCompensationWithAmount[]>>
  inputOrderLines: InputOrderLineCompensationWithAmount[]
  clearOrderPercentage: () => void
  orderPercentage: number | undefined
}

const percentageArray = [5, 10, 15, 20, 25]

export const VariantRow = ({
  orderLine,
  order,
  currencyCode,
  compensations,
  setInputOrderLines,
  inputOrderLines,
  refetch,
  clearOrderPercentage,
  orderPercentage
}: Props) => {
  const [percentage, setPercentage] = useState<number>()
  const [customPercentage, setCustomPercentage] = useState<string>()
  const [showInput, setShowInput] = useState(false)

  const notCompletedCompensation = compensations.find(
    (compensation) => !compensation.started || (compensation.started && !compensation.completed)
  )
  const completedCompensations = compensations.filter((compensation) => !!compensation.completed)
  const orderLineCompensated = completedCompensations
    .map((compensation) =>
      compensation?.orderLines?.find((ol) => ol.orderLineId === orderLine.orderLineId)
    )
    .filter((line) => !!line)

  const [updateCompensation, { loading: updateLoading }] = useMutation(UPDATE_COMPENSATION, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const getOrderLine = () => {
    return order?.orderLines?.find((ol) => ol.id === orderLine.orderLineId)
  }

  const getAmount = () =>
    inputOrderLines.find((inputOrderLine) => inputOrderLine.orderLineId === orderLine.orderLineId)
      ?.compensationAmount ?? 0

  const getCompensatedAmount = () =>
    orderLineCompensated.reduce(
      (a, b) => a + (b.totalDiscountAmount - (getOrderLine()?.totalDiscountAmount ?? 0)),
      0
    )

  const updateOrderCompensation = () => {
    updateCompensation({
      variables: {
        compensationId: notCompletedCompensation?.id,
        input: {
          ...getCompensationInput(notCompletedCompensation),
          payment: {
            amount: (notCompletedCompensation?.payment?.amount ?? 0) + (getAmount() ?? 0)
          },
          orderLines: [
            ...compensationOrderLinesOutputToInput(notCompletedCompensation?.orderLines ?? []),
            toCompensationOrderLineFromState(
              inputOrderLines.find(
                (inputOrderLine) => inputOrderLine.orderLineId === orderLine.orderLineId
              ),
              order.currencyCode
            )
          ]
        }
      }
    })
    setInputOrderLines(
      inputOrderLines.map((orderLine) => ({ ...orderLine, compensationAmount: 0 }))
    )
  }

  const setAmount = (amount: number) => {
    setInputOrderLines(
      inputOrderLines.map((inputOrderLine) =>
        inputOrderLine.orderLineId === orderLine.orderLineId
          ? {
              ...inputOrderLine,
              compensationAmount: amount
            }
          : inputOrderLine
      )
    )
  }

  const handlePercentageClick = (percentageInput: number) => {
    if (percentageInput !== percentage) {
      setCustomPercentage("")
      setPercentage(percentageInput)
      setAmount(Math.floor(orderLine.totalAmount * (percentageInput / 100)))
      clearOrderPercentage()
    }
  }

  const updateAmount = (value: number) => {
    !isNaN(value)
      ? setCustomPercentage(Math.floor((value / orderLine.totalAmount) * 100).toString())
      : setCustomPercentage("")
    setPercentage(undefined)
    setAmount(value)
    clearOrderPercentage()
  }

  const updateCustomPercentage = (percentage: string) => {
    if (
      (parseInt(percentage) && parseInt(percentage) < 101 && parseInt(percentage) > 0) ||
      isNaN(parseInt(percentage))
    ) {
      setPercentage(undefined)
      setCustomPercentage(percentage)
      setAmount(Math.floor(orderLine.totalAmount * (parseInt(percentage) / 100)))
      clearOrderPercentage()
    }
  }

  useEffect(() => {
    if (orderPercentage) {
      if (percentageArray.includes(orderPercentage)) {
        setPercentage(orderPercentage)
        setCustomPercentage("")
        return
      }
      setPercentage(undefined)
      setCustomPercentage(orderPercentage.toString())
    }
  }, [orderPercentage])

  return (
    <RowContainer>
      {getCompensatedAmount() > 0 && (
        <>
          <Compensated>
            Previously compensated amount:{" "}
            <Money
              amount={getCompensatedAmount()}
              currencyUnit={order.currencyCode as DineroFactory.Currency}
            />
          </Compensated>
          <hr />
        </>
      )}

      <RowWrapper key={orderLine.orderLineId}>
        <div>
          <ProductImage src={getOrderLine()?.imageUrl as string} onError={handleImageError} />
          <Name>{getOrderLine()?.name}</Name>
          <PriceWrapper>
            {orderLine.totalDiscountAmount > 0 && (
              <span>
                <Money
                  amount={orderLine.totalAmount + orderLine.totalDiscountAmount}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </span>
            )}
            <b>
              <Money
                amount={orderLine.totalAmount}
                currencyUnit={currencyCode as DineroFactory.Currency}
              />
            </b>
          </PriceWrapper>
          <div>Order quantity: {orderLine.quantity}</div>
          <Id>
            <b>Variant ID:</b> <span>{getOrderLine()?.productVariantId}</span>
          </Id>
        </div>
        <span>
          <Amount>
            <b>Compensation</b>
            <PercentageButtonWrapper>
              {percentageArray.map((value) => (
                <PercentageButton
                  key={value}
                  $selected={value === percentage}
                  $orderPercentage={!!orderPercentage}
                  onClick={() => handlePercentageClick(value)}
                >
                  {value}%
                </PercentageButton>
              ))}
              {(showInput || !customPercentage) && (
                <InputPercentage
                  name={"percentage"}
                  onBlur={() => setShowInput(false)}
                  onWheelCapture={(e) => e.currentTarget.blur()}
                  onFocus={() => setShowInput(true)}
                  type={"number"}
                  value={customPercentage}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updateCustomPercentage(e.target.value)
                  }
                />
              )}
              {!showInput && customPercentage && (
                <PercentageButton
                  $orderPercentage={!!orderPercentage}
                  $selected={true}
                  onClick={() => setShowInput(true)}
                >
                  {customPercentage}%
                </PercentageButton>
              )}
            </PercentageButtonWrapper>
            <AmountInput>
              <Input
                name={"amount"}
                value={getAmount()}
                type="number"
                placeholder={"Amount"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateAmount(parseInt(e.target.value))
                }
              />
              <div>
                <b>Formatted:</b>{" "}
                {getAmount() ? (
                  <Money
                    amount={getAmount() ?? 0}
                    currencyUnit={currencyCode as DineroFactory.Currency}
                  />
                ) : (
                  0
                )}
              </div>
            </AmountInput>
          </Amount>
        </span>
      </RowWrapper>

      {getAmount() > orderLine.totalAmount && (
        <NoticeBanner type={"important"}>Amount to high</NoticeBanner>
      )}
      {notCompletedCompensation && (
        <PrimaryButton
          handleClick={updateOrderCompensation}
          loading={updateLoading}
          disabled={!getAmount() || getAmount() === 0}
        >
          Add order line
        </PrimaryButton>
      )}
    </RowContainer>
  )
}
