import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import EditSidebar from "../../../../Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "../../../../Ui/EditSidebar/EditSidebarHeader"
import { AddOrUpdateProductVariant } from "./AddOrUpdateProductVariant"
import {
  Order,
  OrderLine,
  OrderLineModificationCreateData,
  OrderLineModificationType,
  OrderLineModificationUpdateData,
  OrderModificationOrderLines,
  Price,
  ProductVariant,
  TaxMarket
} from "lib/types/generated/graphql-types"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import CREATE_MODIFICATION from "../../../../../graphql/mutations/order/modification/CreateModification"
import { useMutation } from "@apollo/client"
import UPDATE_MODIFICATION from "../../../../../graphql/mutations/order/modification/UpdateModification"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { useDispatch } from "react-redux"
import { handleErrorMessages } from "helpers/errors"
import { createOutputToInput, updateOutputToInput } from "../Helpers"
import { getDistributedDiscountOnQty } from "../Helpers/helpers"

type Props = {
  productVariant: ProductVariant | undefined
  setProductVariant: Dispatch<SetStateAction<ProductVariant | undefined>>
  storeGroupId: string
  countryCode: string
  currencyCode: string
  order: Order
  notCompletedModifications: OrderModificationOrderLines[]
  refetch: () => void
  editOrderLine: OrderLine | undefined
  setEditOrderLine: Dispatch<SetStateAction<OrderLine | undefined>>
}

const AddOrUpdateOrderLineSideBar = ({
  productVariant,
  setProductVariant,
  storeGroupId,
  countryCode,
  currencyCode,
  order,
  notCompletedModifications,
  refetch,
  editOrderLine,
  setEditOrderLine
}: Props) => {
  const [updatedQuantity, setUpdatedQuantity] = useState(1)
  const [discount, setDiscount] = useState<number>()
  const [taxMarket, setTaxMarket] = useState<TaxMarket | undefined>()
  const [newOrderLineTotal, setNewOrderLineTotal] = useState<number>()
  const dispatch = useDispatch()

  useEffect(() => {
    if (editOrderLine) {
      setUpdatedQuantity(editOrderLine.quantity)
    }
  }, [editOrderLine])

  const reset = () => {
    refetch()
    setProductVariant(undefined)
    setEditOrderLine(undefined)
    setUpdatedQuantity(1)
    setDiscount(undefined)
    dispatch(hideEditSidebar())
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    if (productVariant) {
      const price = productVariant?.StoreGroupPrices.find(
        (storeGroupPrice) => storeGroupPrice.storeGroupId === storeGroupId
      )?.marketPrices?.find((marketPrice) => marketPrice.countryCode === countryCode)?.price

      setNewOrderLineTotal(
        editOrderLine
          ? (price?.basePriceAmount ?? 0) * updatedQuantity -
              getDistributedDiscountOnQty(editOrderLine, currencyCode, updatedQuantity) -
              (discount ?? 0)
          : (price?.salePriceAmount ?? 0) * updatedQuantity - (discount || 0)
      )
    }
  }, [productVariant, discount, updatedQuantity])

  const updateProductVariant = (variant: ProductVariant | undefined) => {
    setProductVariant(variant)
  }

  const [createModification, { loading: createLoading }] = useMutation(CREATE_MODIFICATION, {
    onCompleted: () => {
      reset()
    },
    onError: (error) => handleErrorMessages(error)
  })
  const [updateModification, { loading: updateLoading }] = useMutation(UPDATE_MODIFICATION, {
    onCompleted: () => {
      reset()
    },
    onError: (error) => handleErrorMessages(error)
  })

  const getDisplayName = (productVariant: ProductVariant, languageCode: string) => {
    if (productVariant?.displayNames) {
      const displayNames = JSON.parse(productVariant.displayNames)
      return displayNames[languageCode] ?? productVariant.name
    }
    return productVariant.name
  }

  const getUpdateData = (productVariant: ProductVariant, editOrderLine: OrderLine) => {
    const distributedDiscount = getDistributedDiscountOnQty(
      editOrderLine,
      currencyCode,
      updatedQuantity
    )

    const totalPriceAmount = (editOrderLine.salePriceAmount - (discount ?? 0)) * updatedQuantity
    const distributedTotalPriceAmount =
      editOrderLine.basePriceAmount * updatedQuantity -
      (distributedDiscount + (discount ?? 0) * updatedQuantity)

    return {
      id: editOrderLine.id,
      name: productVariant.name,
      displayName: getDisplayName(productVariant, order.languageCode),
      productVariantId: productVariant.id,
      description: productVariant.description,
      productParentId: productVariant.productParentId,
      taxGroupId: productVariant.TaxGroup.id,
      ...(productVariant.imageUrl && { imageUrl: productVariant.imageUrl }),
      price: {
        quantity: updatedQuantity,
        basePriceAmount: editOrderLine.basePriceAmount,
        salePriceAmount: editOrderLine.salePriceAmount - (discount || 0),
        taxPercentage: editOrderLine.taxPercentage,
        taxPercentageDecimals: editOrderLine.taxPercentageDecimals,
        totalPriceAmount,
        distributedTotalPriceAmount
      }
    }
  }

  const getCreateData = (productVariant: ProductVariant, price: Price) => {
    const totalPriceAmount = (price.salePriceAmount - (discount || 0)) * updatedQuantity

    return {
      productVariantId: productVariant.id,
      name: productVariant.name,
      displayName: getDisplayName(productVariant, order.languageCode),
      description: productVariant.description,
      productParentId: productVariant.productParentId,
      taxGroupId: productVariant.TaxGroup.id,
      ...(productVariant.imageUrl && { imageUrl: productVariant.imageUrl }),
      quantity: updatedQuantity,
      basePriceAmount: price.basePriceAmount,
      salePriceAmount: price.salePriceAmount - (discount || 0),
      taxPercentage: taxMarket?.taxPercentage,
      taxPercentageDecimals: taxMarket?.taxPercentageDecimals,
      totalPriceAmount: totalPriceAmount,
      ...((discount ?? 0) > 0 && {
        distributedTotalPriceAmount: totalPriceAmount
      })
    }
  }

  const getOrderLineInput = (productVariant: ProductVariant, price: Price) => {
    return {
      modificationType: editOrderLine
        ? OrderLineModificationType.Update
        : OrderLineModificationType.Create,
      data: editOrderLine
        ? JSON.stringify(getUpdateData(productVariant, editOrderLine))
        : JSON.stringify(getCreateData(productVariant, price))
    }
  }

  const onCreate = (price: Price) => {
    if (productVariant) {
      createModification({
        variables: {
          orderId: order.id,
          input: {
            orderLines: [getOrderLineInput(productVariant, price)]
          }
        }
      })
    }
  }

  const onUpdate = (price: Price, notCompletedModification: OrderModificationOrderLines) => {
    if (productVariant) {
      const currentOrderLines = notCompletedModification.orderLines
      updateModification({
        variables: {
          modificationId: notCompletedModification.id,
          input: {
            orderLines: [
              ...currentOrderLines
                .filter((orderLine) => orderLine.data.id !== editOrderLine?.id)
                .map((orderLine) => ({
                  ...orderLine,
                  data:
                    orderLine.modificationType === OrderLineModificationType.Update
                      ? JSON.stringify(
                          updateOutputToInput(orderLine.data as OrderLineModificationUpdateData)
                        )
                      : orderLine.modificationType === OrderLineModificationType.Create
                        ? JSON.stringify(
                            createOutputToInput(orderLine.data as OrderLineModificationCreateData)
                          )
                        : JSON.stringify(orderLine.data)
                })),
              getOrderLineInput(productVariant, price)
            ]
          }
        }
      })
    }
  }

  const addOrderLine = () => {
    if (productVariant) {
      const price = productVariant.StoreGroupPrices.find(
        (storeGroupPrice) => storeGroupPrice.storeGroupId === storeGroupId
      )?.marketPrices?.find((marketPrice) => marketPrice.countryCode === countryCode)?.price
      if (price) {
        notCompletedModifications.length > 0
          ? onUpdate(price, notCompletedModifications[0])
          : onCreate(price)
      }
    }
  }

  const cancel = () => {
    setEditOrderLine(undefined)
    dispatch(hideEditSidebar())
    setProductVariant(undefined)
    setUpdatedQuantity(1)
    setDiscount(0)
  }

  const amountToHigh = () => {
    if (newOrderLineTotal) {
      if (!editOrderLine && !notCompletedModifications[0]) {
        return newOrderLineTotal + order.totals.grandTotal > order.totals.grandTotal
      }
      if (!editOrderLine && notCompletedModifications[0]) {
        return (
          newOrderLineTotal + order.totals.grandTotal >
          (notCompletedModifications[0]?.oldTotals?.grandTotal ?? 0)
        )
      }
      if (editOrderLine && !notCompletedModifications[0]) {
        return (
          newOrderLineTotal + order.totals.grandTotal - editOrderLine.distributedTotalPriceAmount >
          order.totals.grandTotal
        )
      }
      if (editOrderLine && notCompletedModifications[0]) {
        return (
          newOrderLineTotal + order.totals.grandTotal - editOrderLine.distributedTotalPriceAmount >
          (notCompletedModifications[0]?.oldTotals?.grandTotal ?? 0)
        )
      }
    }
    return false
  }

  return (
    <EditSidebar cancelEvent={cancel}>
      <EditSidebarHeader
        cancelEvent={cancel}
        title={editOrderLine ? "Edit order line" : "Add Order Line"}
      >
        <PrimaryButton
          disabled={!productVariant || amountToHigh()}
          handleClick={addOrderLine}
          loading={createLoading || updateLoading}
        >
          {editOrderLine ? "Update order line" : "Add order line"}
        </PrimaryButton>
      </EditSidebarHeader>
      <AddOrUpdateProductVariant
        updateProductVariant={updateProductVariant}
        productVariant={productVariant}
        storeGroupId={storeGroupId}
        countryCode={countryCode}
        currencyCode={currencyCode}
        quantity={updatedQuantity}
        setQuantity={setUpdatedQuantity}
        discount={discount}
        setDiscount={setDiscount}
        setTaxMarket={setTaxMarket}
        editOrderLine={editOrderLine}
        order={order}
        amountToHigh={amountToHigh()}
      />
    </EditSidebar>
  )
}

export default AddOrUpdateOrderLineSideBar
