import React, { Dispatch, SetStateAction } from "react"
import dayjs from "dayjs"
import styled from "styled-components"
import { CodesGenerationSearchHit } from "@lib/types/generated/graphql-types"

import { EmptyList, IconTableHeader, Id, Name } from "components/Ui/Table/Shared.styled"

import TableRow from "components/Ui/Table/TableRow"
import Status from "components/Ui/Status"
import TableBlurLoading from "components/Ui/Table/TableBlurLoading"

import { ReactComponent as ListTreeIcon } from "images/icons/list-tree.svg"
import { ApolloError } from "@apollo/client"
import ErrorMessage from "../../Ui/Messages/ErrorMessage"

const MultipleDiscountInfo = styled.div`
  min-width: 40rem;
`

type Props = {
  loading: boolean
  codeGenerationJobs: CodesGenerationSearchHit[]
  setEditCode: Dispatch<SetStateAction<string>>
  error: ApolloError | undefined
}

export const CodeGenerationJobTable = ({
  loading,
  codeGenerationJobs,
  setEditCode,
  error
}: Props) => {
  const tableHeader = (
    <IconTableHeader>
      <MultipleDiscountInfo>Code prefix</MultipleDiscountInfo>
      <div className="hideOnSmallScreens">Requested</div>
      <div className="hideOnSmallScreens">Generated</div>
      <div className="hideOnSmallScreens">Usage limit</div>
      <div className="hideOnSmallScreens">Created</div>
      <div>Status</div>
    </IconTableHeader>
  )

  const getTableRow = (codeGenerationJob?: CodesGenerationSearchHit) => {
    if (codeGenerationJob)
      return (
        <TableRow
          key={codeGenerationJob.id}
          handleOnClick={() => {
            setEditCode(codeGenerationJob.id)
          }}
        >
          <ListTreeIcon />

          <MultipleDiscountInfo>
            <div>
              <Name>{codeGenerationJob.prefix} </Name>
              <Id>Rule ID: {codeGenerationJob.discountCodeRuleId}</Id>
            </div>
          </MultipleDiscountInfo>
          <div className="hideOnSmallScreens">{codeGenerationJob.numberOfCodesRequested}</div>
          <div className="hideOnSmallScreens">{codeGenerationJob.numberOfCodesGenerated}</div>
          <div className="hideOnSmallScreens">{codeGenerationJob.usageLimit}</div>
          <div className="hideOnSmallScreens">
            {dayjs(codeGenerationJob.created).format("YYYY-MM-DD")}
          </div>
          <div>
            <Status status={codeGenerationJob.status} />
          </div>
        </TableRow>
      )
    return (
      <>
        <ListTreeIcon />
        <MultipleDiscountInfo>
          <div>
            <Name>PREFIX - </Name>
            <Id>Rule ID: b40c453e-bc09-4d07-b031-825cb4d89b3d</Id>
          </div>
        </MultipleDiscountInfo>
        <div className="hideOnSmallScreens">200</div>
        <div className="hideOnSmallScreens">200</div>
        <div className="hideOnSmallScreens">1</div>
        <div className="hideOnSmallScreens">2000-00-00</div>
        <div>
          <Status status="COMPLETED" />
        </div>
      </>
    )
  }

  if (loading)
    return <TableBlurLoading numberOfRows={10} tableHeader={tableHeader} rows={getTableRow()} />
  if (error)
    return (
      <ErrorMessage
        showRefreshButton
        message={
          <>
            Error loading discount code generation jobs. Try refreshing the page, or contact{" "}
            <a href="mailto:support@brinkcommerce.com">support</a>.
          </>
        }
      />
    )

  return codeGenerationJobs?.length > 0 ? (
    <div>
      {tableHeader}
      {codeGenerationJobs.map((codeGenerationJob: CodesGenerationSearchHit) => {
        return getTableRow(codeGenerationJob)
      })}
    </div>
  ) : (
    <>
      {!loading && (
        <EmptyList>
          <ListTreeIcon />
          <p> No code generation jobs found</p>
        </EmptyList>
      )}
    </>
  )
}
