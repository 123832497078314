import React, { useState } from "react"
import {
  InputActionType,
  OrderGift,
  OrderLine,
  OrderRefund,
  ShippingFee
} from "lib/types/generated/graphql-types"

import dayjs from "dayjs"
import Money from "../../Money"
import DineroFactory from "dinero.js"
import {
  Action,
  AdditionalData,
  BaseData,
  Bold,
  Container,
  Dates,
  Discount,
  ExpandIcon,
  Footer,
  Id,
  ImageContainer,
  Info,
  Label,
  NotCompleted,
  OrderLineWrapper,
  PaymentInfo,
  PriceWrapper,
  ProductImage,
  ProviderStatus,
  Reason,
  RefundFee,
  Row,
  TotalTitle
} from "./Shared.styled"
import { ReactComponent as DownIcon } from "images/icons/chevron-down.svg"
import { ReactComponent as ShippingIcon } from "images/icons/cart-flatbed-boxes.svg"
import Totals from "./Totals"
import Status from "../../Ui/Status"
import { useQuery } from "@apollo/client"
import GET_REFUND from "../../../graphql/queries/order/GetRefund"
import LoadingRow from "./LoadingRow"
import { handleImageError } from "helpers/image"

type Props = {
  refundId: string
  currencyCode: string
  orderLines: OrderLine[]
  gifts: OrderGift[]
  shippingFees: ShippingFee[]
}
const Refund = ({ refundId, currencyCode, orderLines, gifts, shippingFees }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  const getOrderLine = (id: string) => {
    return orderLines?.find((ol) => ol.id === id)
  }
  const getGiftLine = (id: string) => {
    return gifts?.find((og) => og.id === id)
  }
  const getShippingFee = (id: string) => {
    return shippingFees?.find((fee) => fee.id === id)
  }

  const getGiftCardStatus = (status: string) => {
    switch (status) {
      case "REFUNDED":
        return "Refunded"
      case "REFUND_FAILED":
        return "Failed"
      default:
        return status
    }
  }

  const { data, loading } = useQuery(GET_REFUND, { variables: { refundId: refundId } })

  const refund = data?.Order?.refund as OrderRefund
  if (loading) return <LoadingRow />
  return (
    <Container>
      {!refund.completed && <NotCompleted>Not completed</NotCompleted>}
      <BaseData onClick={() => setExpanded(!expanded)}>
        <h3>Refund #{refund.id}</h3>
        <Dates>
          <div>
            Started: <b>{dayjs(refund.created).format("YYYY-MM-DD : HH:mm:ss")}</b>
          </div>
          <div>
            Last updated: <b>{dayjs(refund.updated).format("YYYY-MM-DD : HH:mm:ss")}</b>
          </div>
          {refund.completed && (
            <div>
              Completed: <b>{dayjs(refund.completed).format("YYYY-MM-DD : HH:mm:ss")}</b>
            </div>
          )}
          <div>
            Order lines: <b>{refund.orderLines.length}</b>
          </div>
          {refund.gifts.length > 0 && (
            <div>
              Gifts: <b>{refund.gifts.length}</b>
            </div>
          )}
          {refund?.restarted && (
            <>
              <div>
                Restarted: <b>{dayjs(refund.restarted).format("YYYY-MM-DD : HH:mm:ss")}</b>
              </div>
              <div>
                Restarts: <b>{refund.restarts}</b>
              </div>
            </>
          )}
        </Dates>
      </BaseData>
      {refund.reason && (
        <Reason>
          Reason:{" "}
          <b>
            {refund.reason?.code}, {refund.reason?.cause}
          </b>
        </Reason>
      )}
      {expanded && (
        <AdditionalData>
          {refund.orderLines.map((ol) => (
            <OrderLineWrapper key={ol.orderLineId}>
              <Info>
                <ImageContainer>
                  <ProductImage
                    src={getOrderLine(ol.orderLineId)?.imageUrl as string}
                    onError={handleImageError}
                  />
                </ImageContainer>
                <div>
                  <Bold>{getOrderLine(ol.orderLineId)?.name}</Bold>
                  <PriceWrapper>
                    <Bold>
                      {" "}
                      <Money
                        amount={parseInt(`${ol.totalAmount}`)}
                        currencyUnit={currencyCode as DineroFactory.Currency}
                      />
                    </Bold>
                    {ol?.totalDiscountAmount && (
                      <Discount>
                        <Money
                          amount={parseInt(`${ol.totalDiscountAmount + ol.totalAmount}`)}
                          currencyUnit={currencyCode as DineroFactory.Currency}
                        />
                      </Discount>
                    )}
                  </PriceWrapper>
                  <div>Order quantity: {getOrderLine(ol.orderLineId)?.quantity}</div>
                  <Id>{getOrderLine(ol.orderLineId)?.productVariantId}</Id>
                </div>
              </Info>
              <Action>
                <span>
                  <b>Action:</b>
                  {getOrderLine(ol.orderLineId)?.quantity === ol.quantity
                    ? "Was refunded"
                    : "Was refunded in part"}
                </span>
                <span>
                  <b>Items refunded:</b> {ol.quantity}/{getOrderLine(ol.orderLineId)?.quantity}
                </span>
                <span>
                  <b>Refunded amount:</b>{" "}
                  <Money
                    amount={parseInt(`${ol.totalAmount}`)}
                    currencyUnit={currencyCode as DineroFactory.Currency}
                  />
                </span>
              </Action>
            </OrderLineWrapper>
          ))}
          {refund.gifts.map((gift) => (
            <OrderLineWrapper key={gift.giftId}>
              <Info>
                <ImageContainer>
                  <ProductImage
                    src={getGiftLine(gift.giftId)?.imageUrl as string}
                    onError={handleImageError}
                  />
                </ImageContainer>
                <div>
                  <Bold>Gift: {getGiftLine(gift.giftId)?.displayName}</Bold>
                  <div>Order quantity: {getGiftLine(gift.giftId)?.quantity}</div>
                  <Id>{getGiftLine(gift.giftId)?.productVariantId}</Id>
                </div>
              </Info>
              <Action>
                <span>
                  <b>Action:</b>
                  {getGiftLine(gift.giftId)?.quantity === gift.quantity
                    ? "Was refunded"
                    : "Was refunded in part"}
                </span>
                <span>
                  <b>Items refunded:</b> {gift.quantity}/{getGiftLine(gift.giftId)?.quantity}
                </span>
              </Action>
            </OrderLineWrapper>
          ))}
          {refund?.shippingFees?.map((fee) => (
            <OrderLineWrapper key={fee.shippingFeeId}>
              <Info>
                <span>
                  <ShippingIcon />
                </span>
                <div>
                  <Bold>Shipping: {getShippingFee(fee.shippingFeeId)?.displayName}</Bold>
                  <Money
                    amount={parseInt(`${fee.totalAmount}`)}
                    currencyUnit={currencyCode as DineroFactory.Currency}
                  />
                </div>
              </Info>
              <Action>
                <span>
                  <b>Action:</b>Was refunded
                </span>
              </Action>
            </OrderLineWrapper>
          ))}
          <PaymentInfo>
            {refund.paymentProvider.status?.current === InputActionType.Manual && (
              <div>
                Payment reference: <b>{refund.refundedPayment?.reference}</b>
              </div>
            )}
          </PaymentInfo>
          {refund.fee && (
            <RefundFee>
              <div>
                Refund fee name: <b>{refund.fee?.name}</b>
              </div>
              <div>
                Refund fee tax:{" "}
                <b>{refund.fee.taxPercentage / 10 ** refund.fee.taxPercentageDecimals + "%"}</b>
              </div>
              <div>
                Refund fee amount:{" "}
                <b>
                  <Money
                    amount={refund.fee.amount}
                    currencyUnit={currencyCode as DineroFactory.Currency}
                  />
                </b>
              </div>
            </RefundFee>
          )}
          <ProviderStatus>
            <div>
              <Label>Payment status:</Label>
              <Status status={refund.paymentProvider.status?.current ?? ""} />
            </div>
            {refund?.bonusProvider && (
              <div>
                <Label>Bonus status:</Label>{" "}
                <Status status={refund?.bonusProvider?.status?.current ?? ""} />
              </div>
            )}
            {refund?.giftCardProvider && (
              <div>
                <Label>Gift card status:</Label>
                <Status status={refund?.giftCardProvider?.status?.current ?? ""} />
              </div>
            )}
          </ProviderStatus>
          <PaymentInfo>
            {refund?.refundedPayment && (
              <div>
                Payment reference: <b>{refund?.refundedPayment?.reference}</b>
              </div>
            )}
            {refund?.bonus && (
              <div>
                Bonus reservationId: <b>{refund?.bonus?.reservationId}</b>
              </div>
            )}
            {refund?.giftCards.length > 0 && (
              <>
                {`Gift card${refund?.giftCards.length > 1 ? "s" : ""}:`}
                {refund.giftCards.map((giftCard) => (
                  <Row key={giftCard.giftCardId}>
                    <span>
                      Id: <b>{giftCard.giftCardId}</b>
                    </span>
                    <span>
                      Amount:{" "}
                      <b>
                        <Money
                          amount={giftCard.amount}
                          currencyUnit={currencyCode as DineroFactory.Currency}
                        />
                      </b>
                    </span>
                    <span>
                      Status: <Status status={getGiftCardStatus(giftCard.status)} />
                    </span>
                  </Row>
                ))}
              </>
            )}
            {refund?.vouchers?.length > 0 && (
              <>
                {`Voucher${refund?.vouchers.length > 1 ? "s" : ""}:`}
                {refund.vouchers.map((voucher) => (
                  <Row key={voucher.voucherId}>
                    <span>
                      provider: <b>{refund?.voucherProvider?.providerName}</b>
                    </span>
                    <span>
                      Id: <b>{voucher.voucherId}</b>
                    </span>
                    <span>
                      Amount:{" "}
                      <b>
                        <Money
                          amount={voucher.amount}
                          currencyUnit={currencyCode as DineroFactory.Currency}
                        />
                      </b>
                    </span>
                  </Row>
                ))}
              </>
            )}
          </PaymentInfo>
          <Totals
            totals={refund.totals}
            currencyCode={currencyCode}
            label="Refunded amount"
            refundFee={refund?.fee}
          />
        </AdditionalData>
      )}
      <Footer>
        <div>
          <TotalTitle>Total:</TotalTitle>
          <TotalTitle>
            <Money
              amount={parseInt(`${refund.totals.grandTotal}`)}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />
          </TotalTitle>
        </div>
      </Footer>
      <ExpandIcon $expanded={expanded} onClick={() => setExpanded(!expanded)}>
        <DownIcon />
      </ExpandIcon>
    </Container>
  )
}

export default Refund
