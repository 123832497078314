import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import styled from "styled-components"
import { Provider } from "lib/types/generated/graphql-types"
import { PROVIDER_TYPE } from "lib/types/common"

import { IconTableHeader, EmptyList } from "components/Ui/Table/Shared.styled"

import ErrorMessage from "../../Ui/Messages/ErrorMessage"
import { KlarnaCheckoutConfigTableRow } from "./KlarnaCheckoutConfigTableRow"
import TableBlurLoading from "../../Ui/Table/TableBlurLoading"
import { getLoadingRow } from "./KlarnaCheckoutConfigTableRow"

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 20rem;
`

type Props = {
  providers: Provider[]
  error: object | undefined
  setEditId?: Dispatch<SetStateAction<string>>
  loading: boolean
}

export const KlarnaCheckoutConfigTable = ({ providers, setEditId, error, loading }: Props) => {
  const [klarnaProviders, setKlarnaProviders] = useState<Provider[]>([])

  const tableHeader = (
    <IconTableHeader>
      <div>Merchant ID</div>
      <div className="hideOnSmallScreens">Base URL</div>
      <div className="hideOnSmallScreens hideOnMeduimScreens">Created</div>
      <div className="hideOnSmallScreens hideOnMeduimScreens">Updated</div>
    </IconTableHeader>
  )

  useEffect(() => {
    if (providers) {
      setKlarnaProviders(
        providers?.filter((provider) => provider.type === PROVIDER_TYPE.KLARNA_CHECKOUT)
      )
    }
  }, [providers])

  if (loading)
    return <TableBlurLoading numberOfRows={3} tableHeader={tableHeader} rows={getLoadingRow()} />

  if (klarnaProviders?.length < 1)
    return (
      <EmptyList>
        <p> No configurations added</p>
      </EmptyList>
    )

  return (
    <Wrapper>
      <Container>
        {error && (
          <ErrorMessage
            showRefreshButton
            message={
              <>
                Error loading Klarna settings. Try refreshing the page, or contact{" "}
                <a href="mailto:support@brinkcommerce.com">support</a>.
              </>
            }
          />
        )}
        {tableHeader}
        {[...klarnaProviders].map((klarnaProvider) => (
          <KlarnaCheckoutConfigTableRow
            key={klarnaProvider.id}
            id={klarnaProvider.id}
            setEditId={setEditId}
          />
        ))}
      </Container>
    </Wrapper>
  )
}
