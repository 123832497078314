import React, { useState } from "react"
import {
  OrderLine,
  OrderLineModificationCreateData,
  OrderLineModificationDeleteData,
  OrderLineModificationType,
  OrderLineModificationUpdateData,
  OrderModificationOrderLines
} from "lib/types/generated/graphql-types"

import dayjs from "dayjs"
import {
  AdditionalData,
  BaseData,
  Bold,
  Container,
  Dates,
  ExpandIcon,
  Id,
  ImageContainer,
  Info,
  Label,
  ModificationStatus,
  NotCompleted,
  OrderLineWrapper,
  PaymentInfo,
  ProductImage,
  ProviderStatus,
  Reason
} from "./Shared.styled"
import { ReactComponent as DownIcon } from "images/icons/chevron-down.svg"
import Status from "../../Ui/Status"
import { useQuery } from "@apollo/client"
import LoadingRow from "./LoadingRow"
import GET_MODIFICATION from "../../../graphql/queries/order/GetModification"
import Money from "../../Money"
import DineroFactory from "dinero.js"
import Totals from "./Totals"
import { handleImageError } from "helpers/image"

type Props = {
  modificationId: string
  currencyCode: string
  orderLines: OrderLine[]
}
const Modification = ({ modificationId, orderLines, currencyCode }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  const getUpdateData = (modificationOrderLine: OrderLineModificationUpdateData) => {
    return modificationOrderLine
  }

  const getCreateAndDeleteData = (
    modificationOrderLine: OrderLineModificationCreateData | OrderLineModificationDeleteData
  ) => {
    return modificationOrderLine
  }

  const getOrderLine = (id: string) => {
    return orderLines?.find((ol) => ol.id === id)
  }

  const getStatus = (status: OrderLineModificationType) => {
    switch (status) {
      case OrderLineModificationType.Update:
        return "Updated"
      case OrderLineModificationType.Create:
        return "Created"
      case OrderLineModificationType.Delete:
        return "Deleted"
    }
    return status
  }

  const { data, loading } = useQuery(GET_MODIFICATION, {
    variables: { modificationId }
  })

  const modification = data?.Order?.orderModificationOrderLines as OrderModificationOrderLines
  const modificationOrderLines = modification?.orderLines ?? []
  if (loading) return <LoadingRow />

  return (
    <Container>
      {!modification?.completed && <NotCompleted>Not completed</NotCompleted>}
      <BaseData onClick={() => setExpanded(!expanded)}>
        <h3>Modification #{modification.id}</h3>
        <Dates>
          <div>
            Started: <b>{dayjs(modification.created).format("YYYY-MM-DD : HH:mm:ss")}</b>
          </div>
          <div>
            Last updated: <b>{dayjs(modification.updated).format("YYYY-MM-DD : HH:mm:ss")}</b>
          </div>
          {modification?.completed && (
            <div>
              Completed: <b>{dayjs(modification?.completed).format("YYYY-MM-DD : HH:mm:ss")}</b>
            </div>
          )}
          <div>
            Order lines: <b>{modificationOrderLines.length}</b>
          </div>
          {modification?.restarted && (
            <>
              <div>
                Restarted: <b>{dayjs(modification.restarted).format("YYYY-MM-DD : HH:mm:ss")}</b>
              </div>
              <div>
                Restarts: <b>{modification.restarts}</b>
              </div>
            </>
          )}
        </Dates>
      </BaseData>
      {modification.reason && (
        <Reason>
          Reason:{" "}
          <b>
            {modification.reason?.code}, {modification.reason?.cause}
          </b>
        </Reason>
      )}
      {expanded && (
        <AdditionalData>
          {modificationOrderLines.map((ol) => (
            <OrderLineWrapper key={ol.data.id}>
              <ModificationStatus
                $deleted={ol.modificationType === OrderLineModificationType.Delete}
              >
                {getStatus(ol.modificationType)}
              </ModificationStatus>
              <Info>
                <ImageContainer>
                  <ProductImage src={ol?.data.imageUrl as string} onError={handleImageError} />
                </ImageContainer>
                <div>
                  <Bold>{ol.data.name}</Bold>
                  <Money
                    amount={
                      (ol.modificationType === OrderLineModificationType.Update
                        ? getUpdateData(ol.data as OrderLineModificationUpdateData)?.price
                            ?.salePriceAmount
                        : getCreateAndDeleteData(
                            ol.data as
                              | OrderLineModificationCreateData
                              | OrderLineModificationDeleteData
                          )?.salePriceAmount) ?? 0
                    }
                    currencyUnit={currencyCode as DineroFactory.Currency}
                  />

                  <div>
                    Order quantity:{" "}
                    {ol.modificationType === OrderLineModificationType.Update
                      ? getUpdateData(ol.data as OrderLineModificationUpdateData)?.price?.quantity
                      : getCreateAndDeleteData(
                          ol.data as
                            | OrderLineModificationCreateData
                            | OrderLineModificationDeleteData
                        )?.quantity}
                  </div>
                  <Id>{getOrderLine(ol.data.id)?.productVariantId}</Id>
                </div>
              </Info>
            </OrderLineWrapper>
          ))}
          <ProviderStatus>
            <div>
              <Label>Payment status:</Label>
              <Status status={modification?.paymentProvider?.status?.current ?? ""} />
            </div>
          </ProviderStatus>
          <PaymentInfo></PaymentInfo>
          {modification.oldTotals && (
            <Totals totals={modification.oldTotals} currencyCode={currencyCode} label="Old total" />
          )}
          {modification.newTotals && (
            <Totals totals={modification.newTotals} currencyCode={currencyCode} label="New total" />
          )}
        </AdditionalData>
      )}
      <ExpandIcon $expanded={expanded} onClick={() => setExpanded(!expanded)}>
        <DownIcon />
      </ExpandIcon>
    </Container>
  )
}

export default Modification
