import styled, { css } from "styled-components"

type StyledProps = {
  $type?: string
  $expanded?: boolean
}

export const Entry = styled.li<StyledProps>`
  display: flex;
  align-items: center;
  gap: 1rem;
  height: ${(p) => p.$expanded ? "12rem": "6.3rem"};

  &:last-of-type {
    > div {
      border-bottom: none;
    }

    > span {
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0 0.8rem 0.8rem 0.8rem;
        border-color: transparent transparent ${(p) => p.theme.colors.greyLight} transparent;
        transform: rotate(0deg);
      }
    }
  }
`

export const Message = styled.div<StyledProps>`
  text-transform: capitalize;
  font-weight: ${(p) => p.$expanded ? "700" : "400"};
  svg {
    width: 1.5rem;
    margin-left: 1rem;
    fill: ${(p) => p.theme.colors.greyDark};
    &:hover {
      cursor: pointer;
    }
  }
  display: flex;
`

export const Info = styled.div`
margin-top: 1rem;
`

export const Url = styled.div`
display: flex;
  a {
    text-decoration: underline;
    margin-left: 0.5rem;
  }
`

export const EntryDate = styled.div`
  background: ${(p) => p.theme.colors.turquoiseLighter};
  border: 0.1rem solid ${(p) => p.theme.colors.turquoiseBorder};
  border-radius: 0.5rem;
  padding: 0.7rem 1.2rem 0.5rem;
  font-size: 1.3rem;
`

export const Timeline = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 3.4rem;
  flex-shrink: 0;

  &:before {
    content: "";
    position: absolute;
    width: 0.2rem;
    height: 100%;
    background: ${(p) => p.theme.colors.greyLight};
    left: 1.6rem;
  }
`

export const Icon = styled.div<StyledProps>`
  width: 3.4rem;
  height: 3.4rem;
  border-radius: 1.7rem;
  background: ${(p) => p.theme.colors.greyLight};
  border: 0.3rem solid ${(p) => p.theme.colors.white};
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(p) =>
  p.$type === "default" &&
  css`
      width: 2.2rem;
      height: 2.2rem;
    `}

  ${(p) =>
  p.$type === "success"
    ? css`
          background: #dcf4e5;
          svg {
            fill: rgb(9, 174, 76);
          }
        `
    : p.$type === "failed"
      ? css`
          background: #ffe6e6;
          svg {
            fill: #e64d4d;
          }
        `
      : ""}

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`

export const EntryEvent = styled.div`
  align-items: center;
  line-height: 1.8rem;
  display: flex;
  gap: 1rem;
  padding: 1.5rem 0;
  width: 100%;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLighter};
  justify-content: space-between;
`
